/*------- Font Family Starts -------*/

@font-face {
    font-family: "Gotham-Light";
    src: url("../fonts/Gotham-Light.ttf");
}

@font-face {
    font-family: "Gotham-Book";
    src: url("../fonts/Gotham-Book.ttf");
}

@font-face {
    font-family: "Gotham-Medium";
    src: url("../fonts/Gotham-Medium.ttf");
}

@font-face {
    font-family: "Gotham-Bold";
    src: url("../fonts/Gotham-Bold.ttf");
}

@font-face {
    font-family: "RobotoCondensed-Light";
    src: url("../fonts/RobotoCondensed-Light.ttf");
}

@font-face {
    font-family: "RobotoCondensed-Regular";
    src: url("../fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
    font-family: "JosefinSlab-Light";
    src: url("../fonts/JosefinSlab-Light.ttf");
}


/*------- Font Family Ends -------*/


/*------- Common CSS Starts -------*/

.container-fluid {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3rem;
}

.loaderText {
    color: #7e7e7e;
    font-size: 40px;
    text-align: center;
    width: 100%;
}

@media (max-width: 1199px) {
    .container-fluid {
        padding-bottom: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 2rem;
    }
}

@media (max-width: 991px) {
    .container-fluid {
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 2rem;
    }
}

@media (max-width: 767px) {
    .container-fluid {
        padding-bottom: 1.5rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 1.5rem;
    }
}

@media (max-width: 575px) {
    .container-fluid {
        padding-bottom: 1.5rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 1.5rem;
    }
}

.section-heading.yellow>h2 {
    color: #ffc400;
}

.section-heading>h2 {
    color: #f7f7f7;
    font-family: "RobotoCondensed-Light";
    font-size: 24px;
    margin-bottom: 2rem;
}

@media (max-width: 1199px) {
    .section-heading>h2 {
        margin-bottom: 1rem;
    }
}

@media (max-width: 991px) {
    .section-heading>h2 {
        margin-bottom: 1rem;
    }
}

@media (max-width: 767px) {
    .section-heading>h2 {
        font-size: 18px;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 575px) {
    .section-heading>h2 {
        font-size: 18px;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

p {
    color: #f7f7f7;
    font-family: "JosefinSlab-Light";
    font-size: 18px;
}

@media (max-width: 1199px) {
    p {
        font-size: 18px;
    }
}

@media (max-width: 991px) {
    p {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    p {
        font-size: 16px;
    }
}

@media (max-width: 575px) {
    p {
        font-size: 16px;
    }
}

a {
    text-decoration: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.read-more>a {
    background-color: transparent;
    border: 1px solid #f7f7f7;
    border-radius: 24px;
    color: #f7f7f7;
    display: inline-block;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
}

.read-more>a:hover {
    background-color: #f7f7f7;
    color: #000000;
}


/*------- Common CSS Ends -------*/


/*------- Reset CSS Starts -------*/

html {
    letter-spacing: 0.75px;
}

body {
    background-color: #000000;
}

@media (max-width: 575px) {
    body.active {
        overflow: hidden;
    }
}


/*------- Reset CSS Ends -------*/


/*------- Scroll Progressbar Section Starts -------*/

.display-progress {
    background-color: #ffc400;
    height: 0.25rem;
    position: fixed;
    top: 0;
    z-index: 2;
}


/*------- Scroll Progressbar Section Ends -------*/


/*------- Home Page Starts -------*/


/*------- Mobile Header Section Starts -------*/

.bg-header-mobile {
    display: none;
}

@media (max-width: 575px) {
    .input-group-header-mobile {
        margin-top: 1rem;
    }

    .header-social-listing-mob {
        margin-top: 1rem;
    }

    .header-social-listing-mob>li {
        margin-right: 0.5rem;
    }

    .header-social-listing-mob>li:last-child {
        margin-right: 0;
    }

    .header-social-listing-mob>li>a {
        border: 1px solid #f7f7f7;
        border-radius: 50%;
        color: #f7f7f7;
        display: block;
        font-size: 1rem;
        height: 2.2rem;
        line-height: 2.2rem;
        text-align: center;
        width: 2.2rem;
    }

    .header-social-listing-mob>li>a:hover {
        background-color: #f7f7f7;
        border: 1px solid transparent;
        color: #000000;
    }
}

@media (max-width: 991px) {
    .bg-header-mobile {
        display: block;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .header-logo-mobile>a {
        display: inline-block;
    }

    .header-logo-mobile>a>img {
        width: 145px;
    }

    .places-characters-listing>li {
        margin-right: 1.5rem;
    }

    .places-characters-listing>li:last-child {
        margin-right: 0;
    }

    .places-characters-listing>li>a {
        color: #f7f7f7;
        font-family: "RobotoCondensed-Light";
        font-size: 16px;
    }

    .burger-menu {
        cursor: pointer;
        height: 3rem;
        margin-left: auto;
        position: relative;
        width: 3rem;
    }

    .burger-menu>span {
        background-color: #f7f7f7;
        height: 0.1rem;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1.5rem;
    }

    .burger-menu>span::before {
        background-color: #f7f7f7;
        content: "";
        height: 0.1rem;
        position: absolute;
        top: 0.75rem;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1rem;
    }

    .burger-menu>span::after {
        background-color: #f7f7f7;
        content: "";
        height: 0.1rem;
        position: absolute;
        top: -0.75rem;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1rem;
    }

    .burger-menu.active>span {
        background-color: transparent;
    }

    .burger-menu.active>span::before {
        top: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

@media (max-width: 991px) and (max-width: 575px) {
    .burger-menu.active>span::before {
        width: 2rem;
    }
}

@media (max-width: 991px) {
    .burger-menu.active>span::after {
        top: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@media (max-width: 991px) and (max-width: 575px) {
    .burger-menu.active>span::after {
        width: 2rem;
    }
}

@media (max-width: 767px) {
    .bg-header-mobile {
        display: block;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .header-logo-mobile>a {
        display: inline-block;
    }

    .header-logo-mobile>a>img {
        width: 145px;
    }

    .places-characters-listing>li {
        margin-right: 1.5rem;
    }

    .places-characters-listing>li:last-child {
        margin-right: 0;
    }

    .places-characters-listing>li>a {
        color: #f7f7f7;
        font-family: "RobotoCondensed-Light";
        font-size: 16px;
    }

    .burger-menu {
        cursor: pointer;
        height: 3rem;
        margin-left: auto;
        position: relative;
        width: 3rem;
    }

    .burger-menu>span {
        background-color: #f7f7f7;
        height: 0.1rem;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1.5rem;
    }

    .burger-menu>span::before {
        background-color: #f7f7f7;
        content: "";
        height: 0.1rem;
        position: absolute;
        top: 0.75rem;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 2rem;
    }

    .burger-menu>span::after {
        background-color: #f7f7f7;
        content: "";
        height: 0.1rem;
        position: absolute;
        top: -0.75rem;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1rem;
    }

    .burger-menu.active>span {
        background-color: transparent;
    }

    .burger-menu.active>span::before {
        top: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-menu.active>span::after {
        top: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@media (max-width: 575px) {
    .bg-header-mobile {
        display: block;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .header-logo-mobile>a {
        display: inline-block;
    }

    .header-logo-mobile>a>img {
        width: 145px;
    }

    .places-characters-listing>li {
        margin-right: 0.5rem;
    }

    .places-characters-listing>li:last-child {
        margin-right: 0;
    }

    .places-characters-listing>li>a {
        color: #f7f7f7;
        font-family: "RobotoCondensed-Light";
        font-size: 14px;
    }

    .burger-menu {
        cursor: pointer;
        height: 3rem;
        margin-left: auto;
        position: relative;
        width: 3rem;
    }

    .burger-menu>span {
        background-color: #f7f7f7;
        height: 0.1rem;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1.5rem;
    }

    .burger-menu>span::before {
        background-color: #f7f7f7;
        content: "";
        height: 0.1rem;
        position: absolute;
        top: -0.75rem;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 2rem;
    }

    .burger-menu>span::after {
        background-color: #f7f7f7;
        content: "";
        height: 0.1rem;
        position: absolute;
        top: 0.75rem;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: 1rem;
    }

    .burger-menu.active>span {
        background-color: transparent;
    }

    .burger-menu.active>span::before {
        top: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-menu.active>span::after {
        top: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}


/*------- Mobile Header Section Ends -------*/


/*------- Mobile Sidebar Section Starts -------*/

.fixed-sidebar {
    display: none;
}

@media (max-width: 991px) {
    .fixed-sidebar {
        background-color: #000000;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        left: 0;
        padding: 1rem;
        position: fixed;
        top: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: auto;
        z-index: 10;
    }

    .fixed-sidebar.active {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    .sidebar-logo>a {
        display: inline-block;
    }

    .sidebar-logo>a>img {
        width: 145px;
    }

    .sidebar-social-listing>li {
        margin-right: 1rem;
    }

    .sidebar-social-listing>li:last-child {
        margin-right: 0;
    }

    .sidebar-social-listing>li>a {
        border: 1px solid #f7f7f7;
        border-radius: 50%;
        color: #f7f7f7;
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        width: 2.5rem;
    }

    .sidebar-social-listing>li>a:hover {
        background-color: #f7f7f7;
        border: 1px solid transparent;
        color: #000000;
    }
}

@media (max-width: 767px) {
    .fixed-sidebar {
        background-color: #000000;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        left: 0;
        padding: 1rem;
        position: fixed;
        top: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: auto;
        z-index: 10;
    }

    .fixed-sidebar.active {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    .sidebar-logo>a {
        display: inline-block;
    }

    .sidebar-logo>a>img {
        width: 145px;
    }

    .sidebar-social-listing>li {
        margin-right: 1rem;
    }

    .sidebar-social-listing>li:last-child {
        margin-right: 0;
    }

    .sidebar-social-listing>li>a {
        border: 1px solid #f7f7f7;
        border-radius: 50%;
        color: #f7f7f7;
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        width: 2.5rem;
    }

    .sidebar-social-listing>li>a:hover {
        background-color: #f7f7f7;
        border: 1px solid transparent;
        color: #000000;
    }
}

@media (max-width: 575px) {
    .fixed-sidebar {
        background-color: #000000;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        left: 0;
        padding: 1rem;
        position: fixed;
        top: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        width: auto;
        z-index: 10;
    }

    .fixed-sidebar.active {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    .sidebar-logo>a {
        display: inline-block;
    }

    .sidebar-logo>a>img {
        width: 145px;
    }

    .sidebar-social-listing>li {
        margin-right: 1rem;
    }

    .sidebar-social-listing>li:last-child {
        margin-right: 0;
    }

    .sidebar-social-listing>li>a {
        border: 1px solid #f7f7f7;
        border-radius: 50%;
        color: #f7f7f7;
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        width: 2.5rem;
    }

    .sidebar-social-listing>li>a:hover {
        background-color: #f7f7f7;
        border: 1px solid transparent;
        color: #000000;
    }
}


/*------- Mobile Sidebar Section Ends -------*/


/*------- Desktop Header Section Starts -------*/

.bg-header-desktop {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

@media (max-width: 575px) {
    .bg-header-desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .bg-header-desktop {
        display: none;
    }
}

@media (max-width: 991px) {
    .bg-header-desktop {
        display: none;
    }
}

.header-logo-desktop>a {
    display: inline-block;
}

.header-logo-desktop>a>img {
    height: 80px;
    object-fit: contain;
}

.bg-latest-trending-stories-sup {
    padding-top: 2rem;
}

.menu-listing>li {
    margin-right: 1.5rem;
}

@media (max-width: 1199px) {
    .menu-listing>li {
        margin-right: 1.25rem;
    }
}

@media (max-width: 991px) {
    .menu-listing>li {
        margin-bottom: 1rem;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .menu-listing>li {
        margin-bottom: 1rem;
        margin-right: 0;
    }
}

@media (max-width: 575px) {
    .menu-listing>li {
        margin-bottom: 1rem;
        margin-right: 0;
    }
}

.menu-listing>li:last-child {
    margin-right: 0;
}

.menu-listing>li>a {
    color: #f7f7f7;
    font-family: "RobotoCondensed-Light";
    font-size: 18px;
}

@media (max-width: 575px) {
    .menu-listing>li>a {
        font-family: "RobotoCondensed-Light";
    }
}

.menu-listing>li:hover>a {
    color: #ffc400;
}

.menu-listing>li.active>a {
    color: #ffc400;
}

.header-social-listing>li {
    margin-right: 1rem;
}

@media (max-width: 1199px) {
    .header-social-listing>li {
        margin-right: 1rem;
    }
}

.header-social-listing>li:last-child {
    margin-right: 0;
}

.header-social-listing>li>a,
.header-social-listing>button>li>a {
    border: 1px solid #f7f7f7;
    border-radius: 50%;
    color: #f7f7f7;
    display: block;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    width: 2.5rem;
    margin-left: 5px;
}

.header-social-listing>li>a:hover,
.header-social-listing>button>li>a:hover {
    background-color: #f7f7f7;
    border: 1px solid transparent;
    color: #000000;
}

.header-social-listing>button {
    background-color: transparent;
    border: none;
    padding: 0px;
    font: inherit;
    color: inherit;
    cursor: pointer;
}

/*------- Desktop Header Section Ends -------*/


/*------- Banner Section Starts -------*/

.bg-slider-home {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.slick-slider .slick-slide {
    margin: 0 0.25rem;
}

@media (max-width: 575px) {
    .slick-slider .slick-slide {
        margin: 0 0.25rem;
    }
}

.slick-slider>.slick-prev {
    border: 2px solid #f7f7f7;
    border-radius: 50%;
    height: 2.5rem;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 2.5rem;
    z-index: 1;
}

@media (max-width: 1199px) {
    .slick-slider>.slick-prev {
        left: -1rem;
    }
}

@media (max-width: 991px) {
    .slick-slider>.slick-prev {
        left: 0;
    }
}

@media (max-width: 767px) {
    .slick-slider>.slick-prev {
        left: 0;
    }
}

@media (max-width: 575px) {
    .slick-slider>.slick-prev {
        height: 2.5rem;
        right: 3rem;
        width: 2.5rem;
    }
}

.slick-slider>.slick-prev::before {
    color: #f7f7f7;
    content: "\f053";
    font-family: "Font Awesome 6 Free";
    font-size: 1rem;
    font-weight: 900;
    opacity: 1;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

@media (max-width: 575px) {
    .slick-slider>.slick-prev::before {
        font-size: 1rem;
    }
}

.slick-slider>.slick-prev:hover,
.slick-slider>.slick-prev:focus {
    background-color: #f7f7f7;
    border: 2px solid transparent;
}

.slick-slider>.slick-prev:hover::before,
.slick-slider>.slick-prev:focus::before {
    color: #000000;
}

.slick-slider>.slick-next {
    border: 2px solid #f7f7f7;
    border-radius: 50%;
    height: 2.5rem;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 2.5rem;
    z-index: 1;
}

.slick-slider-banner-home>.slick-slider>.slick-list {
    max-height: 200px !important;
    padding: 0px 25px !important;
}

@media (max-width: 1199px) {
    .slick-slider>.slick-next {
        right: -1rem;
    }
}

@media (max-width: 991px) {
    .slick-slider>.slick-next {
        right: 0;
    }
}

@media (max-width: 767px) {
    .slick-slider>.slick-next {
        right: 0;
    }
}

@media (max-width: 575px) {
    .slick-slider>.slick-next {
        height: 2.5rem;
        width: 2.5rem;
    }
}

.slick-slider>.slick-next::before {
    color: #f7f7f7;
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    font-size: 1rem;
    font-weight: 900;
    opacity: 1;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

@media (max-width: 575px) {
    .slick-slider>.slick-next::before {
        font-size: 1rem;
    }
}

.slick-slider>.slick-next:hover,
.slick-slider>.slick-next:focus {
    background-color: #f7f7f7;
    border: 2px solid transparent;
}

.slick-slider>.slick-next:hover::before,
.slick-slider>.slick-next:focus::before {
    color: #000000;
}


/*------- Banner Section Ends -------*/


/*------- Information Section Starts -------*/

.bg-info-home {
    padding-top: 0;
}

@media (max-width: 575px) {
    .bg-info-home {
        padding-top: 0;
    }
}


/*------- Information Section Ends -------*/


/*------- Latest and Trending Stories Section Starts -------*/

@media (max-width: 575px) {

    .bg-latest-trending-stories-sup,
    .bg-popular-stories-sup,
    .bg-recommended-stories-sup,
    .bg-new-stories-sup {
        padding-top: 0;
    }
}

@media (max-width: 575px) {
    .section-stories-heading-home>h2 {
        width: calc(100% - 10rem);
    }
}

.slick-slider-latest-trending-stories-home .slick-slide,
.slick-slider-popular-stories-home .slick-slide,
.slick-slider-recommended-stories-home .slick-slide,
.slick-slider-new-stories-home .slick-slide,
.slick-slider-read-stories-pp .slick-slide,
.slick-slider-explore-stories-pp .slick-slide,
.slick-slider-explore-stories-cp .slick-slide,
.slick-slider-read-stories-cp .slick-slide {
    margin: 0 0.25rem;
}

@media (max-width: 575px) {

    .slick-slider-latest-trending-stories-home .slick-slide,
    .slick-slider-popular-stories-home .slick-slide,
    .slick-slider-recommended-stories-home .slick-slide,
    .slick-slider-new-stories-home .slick-slide,
    .slick-slider-read-stories-pp .slick-slide,
    .slick-slider-explore-stories-pp .slick-slide,
    .slick-slider-explore-stories-cp .slick-slide,
    .slick-slider-read-stories-cp .slick-slide {
        margin: 0 0.25rem;
    }
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev,
.slick-slider-popular-stories-home>.slick-prev,
.slick-slider-recommended-stories-home>.slick-slider>.slick-prev,
.slick-slider-new-stories-home>.slick-slider>.slick-prev,
.slick-slider-read-stories-pp>.slick-prev,
.slick-slider-explore-stories-pp>.slick-prev,
.slick-slider-explore-stories-cp>.slick-prev,
.slick-slider-read-stories-cp>.slick-prev {
    border: 2px solid #f7f7f7;
    border-radius: 50%;
    height: 2.5rem;
    left: auto;
    right: 3rem;
    top: -3rem;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 2.5rem;
    z-index: 1;
}

@media (max-width: 575px) {

    .slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev,
    .slick-slider-popular-stories-home>.slick-prev,
    .slick-slider-recommended-stories-home>.slick-slider>.slick-prev,
    .slick-slider-new-stories-home>.slick-slider>.slick-prev,
    .slick-slider-read-stories-pp>.slick-prev,
    .slick-slider-explore-stories-pp>.slick-prev,
    .slick-slider-explore-stories-cp>.slick-prev,
    .slick-slider-read-stories-cp>.slick-prev {
        height: 2.5rem;
        right: 3rem;
        top: -2rem;
        width: 2.5rem;
    }
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev::before,
.slick-slider-popular-stories-home>.slick-prev::before,
.slick-slider-recommended-stories-home>.slick-slider>.slick-prev::before,
.slick-slider-new-stories-home>.slick-slider>.slick-prev::before,
.slick-slider-read-stories-pp>.slick-prev::before,
.slick-slider-explore-stories-pp>.slick-prev::before,
.slick-slider-explore-stories-cp>.slick-prev::before,
.slick-slider-read-stories-cp>.slick-prev::before {
    color: #f7f7f7;
    content: "\f053";
    font-family: "Font Awesome 6 Free";
    font-size: 1rem;
    font-weight: 900;
    opacity: 1;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

@media (max-width: 575px) {

    .slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev::before,
    .slick-slider-popular-stories-home>.slick-prev::before,
    .slick-slider-recommended-stories-home>.slick-slider>.slick-prev::before,
    .slick-slider-new-stories-home>.slick-slider>.slick-prev::before,
    .slick-slider-read-stories-pp>.slick-prev::before,
    .slick-slider-explore-stories-pp>.slick-prev::before,
    .slick-slider-explore-stories-cp>.slick-prev::before,
    .slick-slider-read-stories-cp>.slick-prev::before {
        font-size: 1rem;
    }
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev:hover,
.slick-slider-popular-stories-home>.slick-prev:hover,
.slick-slider-recommended-stories-home>.slick-slider>.slick-prev:hover,
.slick-slider-new-stories-home>.slick-slider>.slick-prev:hover,
.slick-slider-read-stories-pp>.slick-prev:hover,
.slick-slider-explore-stories-pp>.slick-prev:hover,
.slick-slider-explore-stories-cp>.slick-prev:hover,
.slick-slider-read-stories-cp>.slick-prev:hover,
.slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev:focus,
.slick-slider-popular-stories-home>.slick-prev:focus,
.slick-slider-recommended-stories-home>.slick-slider>.slick-prev:focus,
.slick-slider-new-stories-home>.slick-slider>.slick-prev:focus,
.slick-slider-read-stories-pp>.slick-prev:focus,
.slick-slider-explore-stories-pp>.slick-prev:focus,
.slick-slider-explore-stories-cp>.slick-prev:focus,
.slick-slider-read-stories-cp>.slick-prev:focus {
    background-color: #f7f7f7;
    border: 2px solid transparent;
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev:hover::before,
.slick-slider-popular-stories-home>.slick-prev:hover::before,
.slick-slider-recommended-stories-home>.slick-slider>.slick-prev:hover::before,
.slick-slider-new-stories-home>.slick-slider>.slick-prev:hover::before,
.slick-slider-read-stories-pp>.slick-prev:hover::before,
.slick-slider-explore-stories-pp>.slick-prev:hover::before,
.slick-slider-explore-stories-cp>.slick-prev:hover::before,
.slick-slider-read-stories-cp>.slick-prev:hover::before,
.slick-slider-latest-trending-stories-home>.slick-slider>.slick-prev:focus::before,
.slick-slider-popular-stories-home>.slick-prev:focus::before,
.slick-slider-recommended-stories-home>.slick-slider>.slick-prev:focus::before,
.slick-slider-new-stories-home>.slick-slider>.slick-prev:focus::before,
.slick-slider-read-stories-pp>.slick-prev:focus::before,
.slick-slider-explore-stories-pp>.slick-prev:focus::before,
.slick-slider-explore-stories-cp>.slick-prev:focus::before,
.slick-slider-read-stories-cp>.slick-prev:focus::before {
    color: #000000;
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-next,
.slick-slider-popular-stories-home>.slick-next,
.slick-slider-recommended-stories-home>.slick-slider>.slick-next,
.slick-slider-new-stories-home>.slick-slider>.slick-next,
.slick-slider-read-stories-pp>.slick-next,
.slick-slider-explore-stories-pp>.slick-next,
.slick-slider-explore-stories-cp>.slick-next,
.slick-slider-read-stories-cp>.slick-next {
    border: 2px solid #f7f7f7;
    border-radius: 50%;
    height: 2.5rem;
    right: 0;
    top: -3rem;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 2.5rem;
    z-index: 1;
}

@media (max-width: 575px) {

    .slick-slider-latest-trending-stories-home>.slick-slider>.slick-next,
    .slick-slider-popular-stories-home>.slick-next,
    .slick-slider-recommended-stories-home>.slick-slider>.slick-next,
    .slick-slider-new-stories-home>.slick-slider>.slick-next,
    .slick-slider-read-stories-pp>.slick-next,
    .slick-slider-explore-stories-pp>.slick-next,
    .slick-slider-explore-stories-cp>.slick-next,
    .slick-slider-read-stories-cp>.slick-next {
        height: 2.5rem;
        top: -2rem;
        width: 2.5rem;
    }
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-next::before,
.slick-slider-popular-stories-home>.slick-next::before,
.slick-slider-recommended-stories-home>.slick-slider>.slick-next::before,
.slick-slider-new-stories-home>.slick-slider>.slick-next::before,
.slick-slider-read-stories-pp>.slick-next::before,
.slick-slider-explore-stories-pp>.slick-next::before,
.slick-slider-explore-stories-cp>.slick-next::before,
.slick-slider-read-stories-cp>.slick-next::before {
    color: #f7f7f7;
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    font-size: 1rem;
    font-weight: 900;
    opacity: 1;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

@media (max-width: 575px) {

    .slick-slider-latest-trending-stories-home>.slick-slider>.slick-next::before,
    .slick-slider-popular-stories-home>.slick-next::before,
    .slick-slider-recommended-stories-home>.slick-slider>.slick-next::before,
    .slick-slider-new-stories-home>.slick-slider>.slick-next::before,
    .slick-slider-read-stories-pp>.slick-next::before,
    .slick-slider-explore-stories-pp>.slick-next::before,
    .slick-slider-explore-stories-cp>.slick-next::before,
    .slick-slider-read-stories-cp>.slick-next::before {
        font-size: 1rem;
    }
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-next:hover,
.slick-slider-popular-stories-home>.slick-next:hover,
.slick-slider-recommended-stories-home>.slick-slider>.slick-next:hover,
.slick-slider-new-stories-home>.slick-slider>.slick-next:hover,
.slick-slider-read-stories-pp>.slick-next:hover,
.slick-slider-explore-stories-pp>.slick-next:hover,
.slick-slider-explore-stories-cp>.slick-next:hover,
.slick-slider-read-stories-cp>.slick-next:hover,
.slick-slider-latest-trending-stories-home>.slick-slider>.slick-next:focus,
.slick-slider-popular-stories-home>.slick-next:focus,
.slick-slider-recommended-stories-home>.slick-slider>.slick-next:focus,
.slick-slider-new-stories-home>.slick-slider>.slick-next:focus,
.slick-slider-read-stories-pp>.slick-next:focus,
.slick-slider-explore-stories-pp>.slick-next:focus,
.slick-slider-explore-stories-cp>.slick-next:focus,
.slick-slider-read-stories-cp>.slick-next:focus {
    background-color: #f7f7f7;
    border: 2px solid transparent;
}

.slick-slider-latest-trending-stories-home>.slick-slider>.slick-next:hover::before,
.slick-slider-popular-stories-home>.slick-next:hover::before,
.slick-slider-recommended-stories-home>.slick-slider>.slick-next:hover::before,
.slick-slider-new-stories-home>.slick-slider>.slick-next:hover::before,
.slick-slider-read-stories-pp>.slick-next:hover::before,
.slick-slider-explore-stories-pp>.slick-next:hover::before,
.slick-slider-explore-stories-cp>.slick-next:hover::before,
.slick-slider-read-stories-cp>.slick-next:hover::before,
.slick-slider-latest-trending-stories-home>.slick-slider>.slick-next:focus::before,
.slick-slider-popular-stories-home>.slick-next:focus::before,
.slick-slider-recommended-stories-home>.slick-slider>.slick-next:focus::before,
.slick-slider-new-stories-home>.slick-slider>.slick-next:focus::before,
.slick-slider-read-stories-pp>.slick-next:focus::before,
.slick-slider-explore-stories-pp>.slick-next:focus::before,
.slick-slider-explore-stories-cp>.slick-next:focus::before,
.slick-slider-read-stories-cp>.slick-next:focus::before {
    color: #000000;
}

.kathaastu-stories-img-home {
    position: relative;
    z-index: 1;
}

.slick-track {
    margin-left: 0px !important;
}

.kathaastu-stories-img-home img {
    border-radius: 14px;
    cursor: pointer;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 100%;
}

.kathaastu-stories-img-home img:hover {
    -webkit-filter: brightness(40%);
    -moz-filter: brightness(40%);
    -ms-filter: brightness(40%);
    -o-filter: brightness(40%);
    filter: brightness(40%);
}


/*------- Latest and Trending Stories Section Ends -------*/


/*------- Popular Stories Section Starts -------*/


/*------- Popular Stories Section Ends -------*/


/*------- Recommended Stories Section Starts -------*/


/*------- Recommended Stories Section Ends -------*/


/*------- New Stories on Kathaastu Section Starts -------*/

.bg-new-stories-sup {
    padding-bottom: 0;
}


/*------- New Stories on Kathaastu Section Ends -------*/


/*------- Extra Information Section Starts -------*/

.bg-extra-info-home {
    border-bottom: 1px solid #262626;
    padding-bottom: 5rem;
}

@media (max-width: 575px) {
    .bg-extra-info-home {
        padding-bottom: 1.5rem;
    }
}

.extra-info-home>p {
    line-height: 1.7rem;
    margin-bottom: 0.25rem;
}

@media (max-width: 575px) {
    .extra-info-home>p {
        text-align: justify;
    }
}

.extra-info-home>p>span,
.extra-info-home>p>a {
    color: #ffc400;
}

.extra-info-home>p:last-child {
    margin-bottom: 0;
}


/*------- Extra Information Section Ends -------*/


/*------- Footer Section Starts -------*/

.bg-footer {
    padding-bottom: 3rem;
}

@media (max-width: 1199px) {
    .bg-footer {
        padding-bottom: 2rem;
    }
}

@media (max-width: 991px) {
    .bg-footer {
        padding-bottom: 2rem;
    }
}

@media (max-width: 767px) {
    .bg-footer {
        padding-bottom: 2rem;
    }
}

@media (max-width: 575px) {
    .bg-footer {
        padding-bottom: 1.5rem;
    }
}

.footer-listing>li {
    display: block;
    margin-bottom: 1rem;
    text-align: center;
}

.footer-listing>li:last-child {
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .footer-listing>li:last-child {
        margin-bottom: 1rem;
    }
}

.footer-listing>li>a {
    color: #f7f7f7;
    font-family: "RobotoCondensed-Light";
    font-size: 18px;
    position: relative;
}

.footer-listing>li>a::before {
    background-color: #ffc400;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 0;
}

.footer-listing>li>a:hover {
    color: #ffc400;
    letter-spacing: 0.1rem;
    padding-left: 2rem;
}

@media (max-width: 1199px) {
    .footer-listing>li>a:hover {
        letter-spacing: 0.025rem;
    }
}

.footer-listing>li>a:hover::before {
    width: 1.5rem;
}

.footer-listing>li.active>a {
    color: #ffc400;
    letter-spacing: 0.1rem;
    padding-left: 2rem;
}

.footer-listing>li.active>a::before {
    width: 1.5rem;
}

.footer-social-listing,
.story-social-listing-sp,
.blog-social-listing-bp,
.author-social-listing-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media (max-width: 767px) {

    .footer-social-listing,
    .story-social-listing-sp,
    .blog-social-listing-bp,
    .author-social-listing-sp {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 2rem;
    }
}

@media (max-width: 575px) {

    .footer-social-listing,
    .story-social-listing-sp,
    .blog-social-listing-bp,
    .author-social-listing-sp {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-top: 0;
    }
}

.footer-social-listing>li,
.story-social-listing-sp>li,
.blog-social-listing-bp>li,
.author-social-listing-sp>li {
    margin-right: 1rem;
}

.footer-social-listing>li:last-child,
.story-social-listing-sp>li:last-child,
.blog-social-listing-bp>li:last-child,
.author-social-listing-sp>li:last-child {
    margin-right: 0;
}

.footer-social-listing>li>a,
.footer-social-listing>button>li>a,
.story-social-listing-sp>li>a,
.story-social-listing-sp>button>li>a,
.blog-social-listing-bp>li>a,
.blog-social-listing-bp>button>li>a,
.author-social-listing-sp>li>a,
.author-social-listing-sp>button>li>a {
    background-color: #262626;
    border-radius: 50%;
    color: #b7b7b7;
    display: block;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    width: 2.5rem;
    margin-left: 5px;
}

.footer-social-listing>button,
.author-social-listing-sp>button,
.story-social-listing-sp>button,
.blog-social-listing-bp>button {

    background-color: transparent;
    border: none;
    padding: 0px;
    font: inherit;
    color: inherit;
    cursor: pointer;
}

.footer-social-listing>li>a:hover,
.footer-social-listing>button>li>a:hover,
.story-social-listing-sp>li>a:hover,
.story-social-listing-sp>button>li>a:hover,
.blog-social-listing-bp>li>a:hover,
.blog-social-listing-bp>button>li>a:hover,
.author-social-listing-sp>li>a:hover,
.author-social-listing-sp>button>li>a:hover {
    background-color: #ffc400;
    color: #000000;
}

.copyright-info>p {
    border-top: 1px solid #262626;
    font-size: 16px;
    margin: 3rem 0 0;
    padding: 3rem 0 0;
}

@media (max-width: 1199px) {
    .copyright-info>p {
        margin: 2rem 0 0;
        padding: 2rem 0 0;
    }
}

@media (max-width: 991px) {
    .copyright-info>p {
        margin: 2rem 0 0;
        padding: 2rem 0 0;
    }
}

@media (max-width: 767px) {
    .copyright-info>p {
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
    }
}

@media (max-width: 575px) {
    .copyright-info>p {
        margin: 1.5rem 0 0;
        padding: 1.5rem 0 0;
    }
}

.copyright-info>p>span {
    color: #ffc400;
}


/*------- Footer Section Ends -------*/


/*------- Scroll To Top Section Starts -------*/

.scroll-to-top {
    background-color: #ffc400;
    border-radius: 50%;
    bottom: 2rem;
    height: 3rem;
    opacity: 0;
    position: fixed;
    right: 2rem;
    visibility: hidden;
    width: 3rem;
    z-index: 10;
}

.scroll-to-top.active {
    opacity: 1;
    visibility: visible;
}

.scroll-to-top>i {
    color: #f7f7f7;
    display: block;
    font-size: 16px;
    line-height: 3rem;
    text-align: center;
}


/*------- Scroll To Top Section Ends -------*/


/*------- Home Page Ends -------*/


/*------- Stories Page Starts -------*/


/*------- Banner Section Starts -------*/

.bg-banner-sp,
.bg-blog-bp,
.bg-advertise-with-us-ap,
.bg-submissions-sp,
.bg-terms-and-conditions-tcp,
.bg-faq-fp,
.bg-contact-us-cp,
.bg-places-pp,
.bg-characters-cp,
.bg-banner-psp,
.bg-banner-csp {
    padding-top: 1rem;
}

@media (max-width: 991px) {

    .bg-banner-sp,
    .bg-blog-bp,
    .bg-advertise-with-us-ap,
    .bg-submissions-sp,
    .bg-terms-and-conditions-tcp,
    .bg-faq-fp,
    .bg-contact-us-cp,
    .bg-places-pp,
    .bg-characters-cp,
    .bg-banner-psp,
    .bg-banner-csp {
        padding-top: 1rem;
    }
}

@media (max-width: 575px) {

    .bg-banner-sp,
    .bg-blog-bp,
    .bg-advertise-with-us-ap,
    .bg-submissions-sp,
    .bg-terms-and-conditions-tcp,
    .bg-faq-fp,
    .bg-contact-us-cp,
    .bg-places-pp,
    .bg-characters-cp,
    .bg-banner-psp,
    .bg-banner-csp {
        padding-top: 1rem;
    }
}

.story-banner-sp img,
.places-banner-psp img,
.character-banner-csp img {
    width: 100%;
}


/*------- Story Information Section Starts -------*/

.bg-story-info-sp {
    padding-bottom: 5rem;
    padding-top: 2rem;
}

@media (max-width: 991px) {
    .bg-story-info-sp {
        padding-bottom: 1.5rem;
    }
}

@media (max-width: 575px) {
    .bg-story-info-sp {
        padding-bottom: 1.5rem;
        padding-top: 0;
    }
}

.accordion-1 {
    margin-bottom: 2rem;
    margin: -1px;
    border-radius: 4px;
    border: solid 1px transparent;
    background-color: #1c1c1c;
}

@media (max-width: 575px) {
    .accordion-1 {
        margin-bottom: 1rem;
        margin: 0 auto 2rem;
        width: 75%;
    }
}

.accordion-1>h4 {
    /* border: 1px solid #b7b7b7; */
    background-color: #1c1c1c;
    border-bottom: 0;
    color: #b7b7b7;
    cursor: pointer;
    font-family: "RobotoCondensed-Light";
    font-size: 18px;
    margin: 0;
    position: relative;
    width: 100%;
}

@media (max-width: 1199px) {
    .accordion-1>h4 {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .accordion-1>h4 {
        font-size: 16px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .accordion-1 {
        margin-bottom: 1rem;
        margin: 0 auto 2rem;
        width: 75%;
    }

    .accordion-1>h4 {
        font-size: 16px;
        margin: 0 auto;
        width: 100%;
    }

    .audio-story-sp>a {
        font-size: 16px;
        margin: 0 auto 1rem;
        padding: 0.594rem 1rem;
        width: 75%;
    }
}

@media (max-width: 575px) {
    .accordion-1>h4 {
        font-size: 16px;
        margin: 0 auto;
        padding: 0.2444rem 1rem;
        /* width: 75%; */
    }
}


/* .accordion-1 > h4:last-of-type {
  border-bottom: 1px solid #b7b7b7;
} */

.accordion-1>h4::after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.accordion-1>h4.active {
    border: 1px solid #1c1c1c;
    background-color: #1c1c1c;
    color: #f7f7f7;
}

.accordion-1>h4.active::after {
    content: "\f068";
}

.accordion-1>h4>span {
    display: block;
    padding: 0.669rem 1rem;
}

@media (max-width: 575px) {
    .accordion-1>h4>span {
        padding: 0.5rem;
        text-align: center;
    }
}

.accordion-1>div {
    background-color: #111111;
    /* border: 1px solid #000000; */
    padding: 0.5rem;
    width: 100%;
}

@media (max-width: 575px) {
    .accordion-1>div {
        margin: 0 auto;
        width: 100%;
    }
}

.accordion-1>div>p {
    margin-bottom: 0;
    margin-left: 10px;
}

.accordion-1>div>p>a {
    color: #b8b8b8;
    font-family: "RobotoCondensed-Light";
    font-size: 16px;
}

.accordion-1>div>p>a:hover {
    color: #fff;
}

.audio-story-sp {
    text-align: center;
}

.audio-story-sp>a {
    border: 1px solid #b7b7b7;
    color: #b7b7b7;
    display: block;
    font-family: "RobotoCondensed-Light";
    font-size: 18px;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

@media (max-width: 991px) {
    .audio-story-sp>a {
        font-size: 16px;
    }
}

@media (max-width: 575px) {
    .audio-story-sp>a {
        font-size: 16px;
        margin: 0 auto 1rem;
        padding: 0.594rem 1rem;
        width: 75%;
    }
}

.select {
    margin-bottom: 2rem;
    margin-left: auto;
    position: relative;
    width: 100%;
}

@media (max-width: 991px) {
    .select {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .select {
        margin: 0 auto 1rem;
        text-align: center;
        width: 75%;
    }
}

.select>.selectBtn {
    /* border: 1px solid #b7b7b7; */
    color: #b7b7b7;
    cursor: pointer;
    font-family: "RobotoCondensed-Light";
    padding: 0.594rem 0.75rem;
    position: relative;
    width: 100%;
    background-color: #1c1c1c;
    border-radius: 4px;
}

@media (max-width: 991px) {
    .select>.selectBtn {
        font-size: 16px;
    }
}

.select>.selectBtn::after {
    border-right: 2px solid #b7b7b7;
    border-bottom: 2px solid #b7b7b7;
    content: "";
    height: 0.75rem;
    position: absolute;
    right: 15px;
    top: 45%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    width: 0.75rem;
}

.select>.selectBtn.toggle::after {
    -webkit-transform: translateY(-50%) rotate(-135deg);
    transform: translateY(-50%) rotate(-135deg);
}

.select>.selectDropdown {
    background-color: #111111;
    font-family: "RobotoCondensed-Light";
    overflow: hidden;
    position: absolute;
    top: 100%;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    visibility: hidden;
    width: 100%;
    z-index: 1;
    border: solid 1px #000;
}

.select>.selectDropdown>.option {
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    color: #b8b8b8;
}

.select>.selectDropdown>.option:last-child {
    border-radius: 0 0 4px 4px;
}

.select>.selectDropdown>.option:hover {
    background-color: #262626;
    color: #f7f7f7;
}

.select>.selectDropdown.toggle {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    visibility: visible;
}

.story-heading-sp {
    text-align: center;
}


/* @media (max-width: 575px) {
  .story-heading-sp {
    padding: 0 1rem;
  }
} */


/* 
.story-info-sp>p:last-of-type {
  margin-bottom: 4rem;
} */


/* @media (max-width: 767px) {
  .story-info-sp>p:last-of-type {
    margin-bottom: 2rem;
  }
} */


/* @media (max-width: 575px) {
  .story-info-sp>p:last-of-type {
    margin-bottom: 1rem;
  }
} */

.story-info-sp>p:last-of-type {
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .story-info-sp>p {
        /* padding: 0 1rem; */
        text-align: justify;
    }
}

@media (max-width: 575px) {

    .story-social-heading-sp>p,
    .blog-social-heading-bp>p {
        padding: 0 1rem;
    }
}

.accordion-story-ahead-sp>h4 {
    width: 100%;
}

@media (max-width: 575px) {
    .accordion-story-ahead-sp>h4 {
        /* margin-top: 0.5rem; */
        width: 75%;
    }
}

.story-social-listing-sp,
.blog-social-listing-bp {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media (max-width: 575px) {

    .story-social-listing-sp,
    .blog-social-listing-bp {
        margin-bottom: 1rem;
        padding: 0 1rem;
    }
}

.story-tags-info-sp>p {
    margin-bottom: 0;
}

.story-tags-info-sp>p>a {
    color: #ffc400;
    padding-right: 1rem;
}


/* .in-this-story-heading-sp {
  margin-top: 2rem;
} */

@media (max-width: 575px) {
    .in-this-story-heading-sp {
        margin-top: 0;
    }
}

.story-tags-heading-sp>p {
    font-family: "RobotoCondensed-Light";
    font-size: 24px;
    margin-bottom: 0.5rem;
}

.story-tags-listing-sp>li {
    display: inline-block;
    margin: 0 0.5rem 0.85rem 0;
}

.story-tags-listing-sp>li:last-child {
    margin-right: 0;
}

.story-tags-listing-sp>li>a {
    border: 1px solid #b7b7b7;
    color: #b7b7b7;
    display: inline-block;
    font-family: "Gotham-Book";
    font-size: 14px;
    padding: 0.3rem 0.4rem;
    border-radius: 4px;
}

@media (max-width: 575px) {
    .story-tags-listing-sp>li>a {
        font-size: 16px;
    }
}

.story-tags-listing-sp>li>a:hover {
    color: #ffc400;
}


/*------- Story Information Section Ends -------*/


/*------- Author Information Section Starts -------*/

.bg-author-info-sp {
    border-bottom: 1px solid #262626;
    border-top: 1px solid #262626;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

@media (max-width: 575px) {
    .bg-author-info-sp {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
    }
}

.author-profile-sp {
    margin-right: 1.5rem;
}

.author-profile-sp>img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

.author-info-sp>h3 {
    color: #b7b7b7;
    font-family: "Gotham-Book";
    font-size: 18px;
    margin-bottom: 1rem;
}

.author-social-listing-sp {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}


/*------- Author Information Section Ends -------*/


/*-------Story-charc-places Information Section Starts -------*/

.story-char-pla-info {
    border-bottom: 1px solid #262626;
    padding-bottom: 3rem;
}


/*-------Story-charc-places Information Section Ends -------*/


/*------- Stories Page Ends -------*/


/*------- Blog Page Starts -------*/


/*------- Blog Section Starts -------*/

@media (max-width: 1199px) {
    .bg-blog-bp {
        padding-bottom: 0;
    }
}

@media (max-width: 991px) {
    .bg-blog-bp {
        padding-bottom: 0;
    }
}

@media (max-width: 767px) {
    .bg-blog-bp {
        padding-bottom: 0;
    }
}

@media (max-width: 767px) {
    .select {
        margin: 0 auto 1rem;
        text-align: center;
        width: 75%;
    }

    .audio-story-sp>a {
        font-size: 16px;
        margin: 0 auto 1rem;
        padding: 0.594rem 1rem;
        width: 75%;
    }
}

@media (max-width: 575px) {
    .bg-blog-bp {
        padding-bottom: 1rem;
    }
}

.blog-banner-bp img {
    margin-bottom: 2rem;
}

@media (max-width: 575px) {
    .blog-banner-bp img {
        margin-bottom: 1.5rem;
    }
}

.blog-section-heading {
    text-align: center;
}

@media (max-width: 575px) {
    .blog-section-heading>h2 {
        margin-bottom: 1rem;
    }
}

@media (max-width: 575px) {
    .blog-social-heading-bp>p {
        padding: 0;
    }
}

.blog-social-listing-bp {
    margin-bottom: 3rem;
}

@media (max-width: 767px) {
    .blog-social-listing-bp {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .blog-social-listing-bp {
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        padding: 0;
    }
}


/*------- Blog Section Ends -------*/


/*------- Blog Page Ends -------*/


/*------- Advertise With Us Page Starts -------*/


/*------- Advertise With Us Section Starts -------*/

@media (max-width: 575px) {

    .bg-advertise-with-us-ap,
    .bg-submissions-sp,
    .bg-terms-and-conditions-tcp,
    .bg-faq-fp,
    .bg-contact-us-cp {
        padding-bottom: 0;
    }
}

.advertise-info-ap>p,
.submissions-info-sp>p,
.terms-conditions-info-tcp>p,
.contact-info-cp>p {
    margin-bottom: 2.5rem;
}

@media (max-width: 1199px) {

    .advertise-info-ap>p,
    .submissions-info-sp>p,
    .terms-conditions-info-tcp>p,
    .contact-info-cp>p {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 991px) {

    .advertise-info-ap>p,
    .submissions-info-sp>p,
    .terms-conditions-info-tcp>p,
    .contact-info-cp>p {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 767px) {

    .advertise-info-ap>p,
    .submissions-info-sp>p,
    .terms-conditions-info-tcp>p,
    .contact-info-cp>p {
        margin-bottom: 1rem;
    }
}

@media (max-width: 575px) {

    .advertise-info-ap>p,
    .submissions-info-sp>p,
    .terms-conditions-info-tcp>p,
    .contact-info-cp>p {
        margin-bottom: 1rem;
    }
}

.advertise-info-ap>p>a,
.submissions-info-sp>p>a,
.terms-conditions-info-tcp>p>a,
.contact-info-cp>p>a {
    color: #ffc400;
}

.advertise-info-ap>p>a:hover,
.submissions-info-sp>p>a:hover,
.terms-conditions-info-tcp>p>a:hover,
.contact-info-cp>p>a:hover {
    text-decoration: underline;
}

.form-label-kathaastu {
    color: #f7f7f7;
    font-family: "RobotoCondensed-Light";
    font-size: 18px;
}

@media (max-width: 575px) {
    .form-label-kathaastu {
        font-size: 16px;
    }
}

.form-control-kathaastu {
    border: 0;
    border-radius: 0;
    font-family: "RobotoCondensed-Light";
}

.form-control-dark-kathaastu {
    background-color: #262626;
    color: #f7f7f7;
}

.form-control-dark-kathaastu::-webkit-input-placeholder {
    color: #f7f7f7;
    letter-spacing: 0.05rem;
}

.form-control-dark-kathaastu:-ms-input-placeholder {
    color: #f7f7f7;
    letter-spacing: 0.05rem;
}

.form-control-dark-kathaastu::-ms-input-placeholder {
    color: #f7f7f7;
    letter-spacing: 0.05rem;
}

.form-control-dark-kathaastu::placeholder {
    color: #f7f7f7;
    letter-spacing: 0.05rem;
}

.form-control-dark-kathaastu:-ms-input-placeholder {
    color: #f7f7f7;
    letter-spacing: 0.05rem;
}

.form-control-dark-kathaastu::-ms-input-placeholder {
    color: #f7f7f7;
    letter-spacing: 0.05rem;
}

.form-control-dark-kathaastu+.input-group-text {
    background-color: #b7b7b7;
    border-color: #b7b7b7;
}

.form-control-dark-kathaastu:focus {
    background-color: #262626;
    color: #f7f7f7;
}

textarea.form-control-kathaastu {
    height: 150px;
}

.btn-cancel,
.btn-submit {
    font-family: "Gotham-Medium";
    letter-spacing: 0.05rem;
    margin-right: 1rem;
    padding: 0.563rem 0.75rem;
    text-transform: uppercase;
}

.btn-submit {
    margin-right: 0;
}


/*------- Advertise With Us Section Ends -------*/


/*------- Advertise With Us Page Ends -------*/


/*------- Submissions Page Starts -------*/


/*------- Submissions Section Starts -------*/

.submissions-info-sp>h3,
.terms-conditions-info-tcp>h3,
.contact-info-cp>h3 {
    color: #ffffff;
    font-family: "RobotoCondensed-Light";
    font-size: 24px;
    margin-bottom: 1rem;
    padding-left: 3rem;
    position: relative;
}

.submissions-info-sp>h3::before,
.terms-conditions-info-tcp>h3::before,
.contact-info-cp>h3::before {
    background-color: #ffffff;
    content: "";
    display: block;
    height: 0.05rem;
    left: 0;
    position: absolute;
    top: 50%;
    width: 2rem;
}

@media (max-width: 1199px) {

    .submissions-info-sp>h3,
    .terms-conditions-info-tcp>h3,
    .contact-info-cp>h3 {
        font-size: 18px;
    }
}

@media (max-width: 991px) {

    .submissions-info-sp>h3,
    .terms-conditions-info-tcp>h3,
    .contact-info-cp>h3 {
        font-size: 18px;
    }
}

@media (max-width: 767px) {

    .submissions-info-sp>h3,
    .terms-conditions-info-tcp>h3,
    .contact-info-cp>h3 {
        font-size: 18px;
    }
}

@media (max-width: 575px) {

    .submissions-info-sp>h3,
    .terms-conditions-info-tcp>h3,
    .contact-info-cp>h3 {
        font-size: 18px;
    }
}


/*------- Submissions Section Ends -------*/


/*------- Submissions Page Ends -------*/


/*------- Terms and Conditions Page Starts -------*/


/*------- Terms and Conditions Section Starts -------*/

@media (max-width: 575px) {
    .terms-conditions-info-tcp>h3 {
        line-height: 1.5;
    }
}

@media (max-width: 1199px) {
    .terms-conditions-info-tcp {
        margin-top: 1.5rem;
    }
}

@media (max-width: 991px) {
    .terms-conditions-info-tcp {
        margin-top: 1.5rem;
    }
}

@media (max-width: 767px) {
    .terms-conditions-info-tcp {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .terms-conditions-info-tcp {
        margin-top: 0;
    }
}


/*------- Terms and Conditions Section Ends -------*/


/*------- Terms and Conditions Page Ends -------*/


/*------- FAQ Page Starts -------*/


/*------- FAQ Section Starts -------*/

@media (max-width: 1199px) {
    .faq-accordion-fp {
        margin-top: 1rem;
    }
}

@media (max-width: 991px) {
    .faq-accordion-fp {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .faq-accordion-fp {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    .faq-accordion-fp {
        margin-top: 0;
    }
}

.faq-accordion-fp .accordion-item {
    border: 1px solid #000000;
}

.faq-accordion-fp .accordion-item .accordion-button {
    background-color: #1c1c1c;
    color: #b7b7b7;
    font-family: "RobotoCondensed-Light";
    font-size: 20px;
    line-height: 27px;
}

.faq-accordion-fp .accordion-item .accordion-button:not(.collapsed) {
    background-color: #ffc400;
    color: #1c1c1c;
}


/* .faq-accordion-fp .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("../images/arrow.png");
} */

.accordion-button:not(.collapsed)::after {
    background-image: unset;
}

.accordion-item {
    background-color: #0f0f0f;
}

.faq-accordion-fp .accordion-item .accordion-button:focus {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}

.faq-accordion-fp .accordion-item .accordion-body {
    color: #b7b7b7;
    font-family: "Gotham-Book";
    font-size: 16px;
}

.faq-accordion-fp .accordion-item .accordion-body>a {
    color: #262626;
    text-transform: uppercase;
}

.faq-accordion-fp .accordion-item .accordion-body>a:hover {
    text-decoration: underline;
}

.accordion-button::after {
    content: '\f107';
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    background-image: unset;
}


/*------- FAQ Section Ends -------*/


/*------- FAQ Page Ends -------*/


/*------- Contact Us Page Starts -------*/


/*------- Contact Us Section Starts -------*/


/*------- Contact Us Section Ends -------*/


/*------- Contact Us Page Ends -------*/


/*------- Places Page Starts -------*/


/*------- Places Section Starts -------*/

@media (max-width: 575px) {

    .bg-places-pp,
    .bg-characters-cp {
        padding-bottom: 0;
    }
}

.places-info-pp>p,
.characters-info-cp>p {
    font-size: 20px;
    margin-bottom: 0;
}

@media (max-width: 767px) {

    .places-info-pp>p,
    .characters-info-cp>p {
        margin-bottom: 1rem;
    }
}

@media (max-width: 575px) {

    .places-info-pp>p,
    .characters-info-cp>p {
        font-size: 16px;
        margin-bottom: 1rem;
    }
}

.form-control-kathaastu+.input-group-text {
    border-radius: 0;
    cursor: pointer;
}

.row-box-places-images-pp,
.row-box-extra-places-images-pp,
.row-box-extra-characters-images-cp,
.row-box-characters-images-cp {
    margin-top: 1.5rem;
}

@media (max-width: 575px) {

    .row-box-places-images-pp,
    .row-box-extra-places-images-pp,
    .row-box-extra-characters-images-cp,
    .row-box-characters-images-cp {
        margin: 1.5rem -0.5rem 0;
    }
}

@media (max-width: 575px) {

    .row-box-places-images-pp>.col-6,
    .row-box-extra-places-images-pp>.col-6,
    .row-box-extra-characters-images-cp>.col-6,
    .row-box-characters-images-cp>.col-6 {
        padding: 0 0.5rem;
    }
}

.box-places-images-pp>a,
.box-characters-images-cp>a {
    display: block;
    margin-bottom: 1.5rem;
}

@media (max-width: 575px) {

    .box-places-images-pp>a,
    .box-characters-images-cp>a {
        margin-bottom: 1rem;
    }
}

.box-places-images-pp>a>img,
.box-characters-images-cp>a>img {
    border-radius: 14px;
}

.box-places-images-pp>a:hover,
.box-characters-images-cp>a:hover {
    -webkit-filter: brightness(40%);
    -moz-filter: brightness(40%);
    -ms-filter: brightness(40%);
    -o-filter: brightness(40%);
    filter: brightness(40%);
}


/*------- Places Section Ends -------*/


/*------- Read Stories Section Starts -------*/

@media (max-width: 575px) {

    .bg-read-stories-pp,
    .bg-read-stories-cp {
        padding-bottom: 0;
    }
}


/*------- Read Stories Section Ends -------*/


/*------- Explore Stories Section Starts -------*/

@media (max-width: 575px) {

    .bg-explore-stories-pp,
    .bg-explore-stories-cp {
        padding-bottom: 0;
    }
}


/*------- Explore Stories Section Ends -------*/


/*------- Extra Places Section Starts -------*/

@media (max-width: 575px) {

    .bg-extra-places-pp,
    .bg-extra-characters-cp {
        padding-bottom: 0;
    }
}


/*------- Extra Places Section Ends -------*/


/*------- Places Page Ends -------*/


/*------- Places Single Page Starts -------*/


/*------- Banner Section Starts -------*/

@media (max-width: 575px) {

    .bg-banner-psp,
    .bg-banner-csp {
        padding-bottom: 0;
    }
}

@media (max-width: 575px) {

    .places-banner-psp>img,
    .character-banner-csp>img {
        margin-bottom: 1rem;
    }
}

.places-banner-info-psp>p,
.character-banner-info-csp>p {
    margin-bottom: 0;
    text-align: justify;
}


/*------- Banner Section Ends -------*/


/*------- Story Set Section Starts -------*/

@media (max-width: 575px) {
    .row-stories-set-city {
        margin: 0 -0.5rem;
    }
}

@media (max-width: 575px) {
    .row-stories-set-city>.col-6 {
        padding: 0 0.5rem;
    }
}


/*------- Story Set Section Ends -------*/


/*------- Places Single Page Ends -------*/


/*------- Characters Page Starts -------*/


/*------- Characters Section Starts -------*/


/*------- Characters Section Ends -------*/


/*------- Read Stories Section Starts -------*/


/*------- Read Stories Section Ends -------*/


/*------- Explore Stories Section Starts -------*/


/*------- Explore Stories Section Ends -------*/


/*------- Extra Characters Section Starts -------*/


/*------- Extra Characters Section Ends -------*/


/*------- Characters Page Ends -------*/


/*------- Characters Single Page Starts -------*/


/*------- Banner Section Starts -------*/

@media (max-width: 575px) {
    .d-block-mobile {
        display: block;
    }
}

@media (max-width: 991px) {
    .character-banner-csp>img {
        margin-bottom: 1rem;
    }
}

@media (max-width: 767px) {
    .character-banner-csp>img {
        margin-bottom: 1rem;
    }
}

@media (max-width: 575px) {
    .character-banner-csp>img {
        margin-bottom: 1rem;
    }
}


/*------- Banner Section Ends -------*/


/*------- Story Set Section Starts -------*/

.footer-social-listing {
    justify-content: center;
    margin-top: 24px;
}

.widget-title {
    font-family: "RobotoCondensed-Light";
    font-size: 20px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #fff;
}

@media (max-width: 575px) {
    .row-stories-set-city {
        margin: 0 -0.5rem;
    }
}


/*------- Story Set Section Ends -------*/


/*------- Characters Single Page Ends -------*/


/* The switch - the box around the slider Starts */

.darkLight.switch {
    position: absolute;
    display: inline-block;
    /* width: 60px;
  height: 34px; */
    top: 10px;
    right: 10px;
    /* top: 50%;
  right: 0;
  transform: translate(0, -50%); */
}


/* Hide default HTML checkbox */


/* .switch input {
  opacity: 0;
  width: 0;
  height: 0;
} */


/* The slider */


/* .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
} */


/* .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateY(-26px);
  -ms-transform: translateY(-26px);
  transform: translateY(-26px);
} */


/* Rounded sliders */


/* .slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} */

input[class="light-dark-switch-input"] {
    display: none;
}

input[class="light-dark-switch-input"]+span {
    background-color: #000;
    border: solid 2px #b7b7b7;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    cursor: pointer;
    display: block;
    height: 40px;
    position: relative;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
    width: 80px;
    /* transform: translate(0, -50%); */
}

input[class="light-dark-switch-input"]+span::before {
    content: url('../images/light-switch.png');
    left: 5px;
    position: absolute;
    /* transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%); */
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}

input[class="light-dark-switch-input"]:checked+span {
    background-color: #fff;
    border: solid 2px #f3cb22;
}

input[class="light-dark-switch-input"]:checked+span:before {
    content: url('../images/light-switch-new.png');
    left: 34px;
    top: 2px;
}

.story-social-heading-sp p {
    font-size: 20px;
    color: #ffc400;
}

.footer-logo img {
    height: 60px;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-logo+p {
    font-size: 16px;
}

.bg-footer a {
    color: #ffc400;
}

.custom-story-box {
    background-color: #1c1c1c;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}


/* body.active {
  background-color: #fff;
}

body.active .header-logo-desktop img {
  filter: brightness(0%);
}

body.active .menu-listing>li>a {
  color: #212529;
}

body.active .menu-listing>li.active>a {
  color: #000;
  font-weight: 600;
}

body.active .header-social-listing>li>a {
  border: 1px solid #212529;
  color: #212529;
}

body.active .header-social-listing>li>a:hover {
  background-color: #212529;
  border: 1px solid transparent;
  color: #fff;
}

body.active .accordion-1>h4,
body.active .audio-story-sp>a,
body.active .select>.selectBtn {
  color: #212529;
  border: 1px solid #212529;
  background-color: #ffffff;
  border-radius: 4px;

}

body.active .select>.selectBtn::after {
  border-right: 2px solid #212529;
  border-bottom: 2px solid #212529;
}

body.active p {
  color: #212529;
  font-weight: 600;
}

body.active .section-heading>h2 {
  color: #212529;
}

body.active .author-info-sp>h3 {
  color: #000;
}

body.active .footer-listing>li>a {
  color: #212529;
}

body.active .footer-listing>li>a:hover {
  color: #ffc400;
}

body.active .accordion-1>div {
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 0 0 4px 4px;
}

body.active .accordion-1>div>p>a {
  color: #212529;
  font-weight: 500;
  transition: all 0.3s ease;
}

body.active .accordion-1>div>p>a:hover {
  color: #000000;
}

body.active .select>.selectDropdown {
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
}

body.active .select>.selectDropdown>.option {
  color: #212529;
}

body.active .select>.selectDropdown>.option:hover {
  background-color: #f6f6f6;
  color: #212529;
}

body.active .accordion-1 {
  border: unset;
}

body.active .story-tags-listing-sp>li>a {
  border: 1px solid #212529;
  color: #212529;
}

body.active .story-tags-listing-sp>li>a:hover {
  border: 1px solid #212529;
  color: #fff;
  background-color: #212529;
} */

body.light .custom-story-box {
    background-color: #fff;
}

body.light .section-heading.yellow>h2 {
    color: #000000;
}

body.light .story-info-sp p,
body.light .blog-info-bp p {
    color: #000;
    font-family: "JosefinSlab";
}

@media (max-width: 575px) {
    .row-stories-set-city>.col-6 {
        padding: 0 0.5rem;
    }

    .row.col-reverse {
        flex-flow: column-reverse;
    }

    .story-social-heading-sp span {
        display: block;
    }

    .story-social-heading-sp,
    .blog-social-heading-bp {
        text-align: center;
    }

    .story-social-listing-sp,
    .blog-social-listing-bp {
        justify-content: center;
    }

    .footer-listing>li {
        text-align: left;
    }

    .footer-listing {
        margin-bottom: 40px;
    }

    .footer-social-listing {
        justify-content: left;
        margin-top: 10px;
    }

    .widget-title {
        margin-bottom: 10px;
    }

    .footer-content {
        margin-bottom: 20px;
    }

    .story-heading-sp,
    .blog-section-heading {
        text-align: center;
        padding-top: 40px;
    }

    /* .darkLight.switch {
    top: 10px;
    right: 10px;
  } */
}

.BannnerImg {
    display: none;
}
.mobileBannnerImg{
    display: none;
}
@media screen and (max-width: 500px) {
    .mobileBannnerImg {
        width: 100%;
        height: auto;
        display: block;
    }
    .BannnerImg {
        display: none !important;
    }
}
@media screen and (min-width: 500px) {
    .BannnerImg {
        width: 100%;
        height:  333px;
        display: block;
    }
    .mobileBannnerImg{
        display: none !important;
    }
    .slick-slider-banner-home>.slick-slider>.slick-list {
        max-height: 333px !important;
        padding: 0px 25px !important;
    }
}

.example-enter {
    height: 0px;
}

.example-enter.example-enter-active {
    height: 100px;
    -webkit-transition: height .3s ease;
}

.example-leave.example-leave-active {
    height: 0px;
    -webkit-transition: height .3s ease;
}

.slick-slider-recommended-stories-home>.slick-slider>.slick-list > .slick-track00{
    /* width:100% !important; */
}
.sliderImage{
    height: 200px;
    width:160px !important;
}
@media screen and (max-width:600px){
    .sliderImage{
        height: 162px !important;
        width: 125px !important;
    }   
}
@media screen and (max-width:400px){
    .sliderImage{
        height: 150px !important;
        width: 90px !important;
    }   
    h2{
        width:100% !important;
    }
    /* .kathaastu-stories-img-home img
    {
        height:150px ;
    } */
}

p
{
    font-family: "RobotoCondensed-Light" !important;
}