@mixin box-shadow($box-shadow-value) {
  -webkit-box-shadow: $box-shadow-value;
  -moz-box-shadow: $box-shadow-value;
  -ms-box-shadow: $box-shadow-value;
  -o-box-shadow: $box-shadow-value;
  box-shadow: $box-shadow-value;
}
@mixin border-radius($border-radius-value) {
  -webkit-border-radius: $border-radius-value;
  -moz-border-radius: $border-radius-value;
  -ms-border-radius: $border-radius-value;
  -o-border-radius: $border-radius-value;
  border-radius: $border-radius-value;
}
@mixin transform($transform-value) {
  -webkit-transform: $transform-value;
  -moz-transform: $transform-value;
  -ms-transform: $transform-value;
  -o-transform: $transform-value;
  transform: $transform-value;
}
@mixin transform-origin($transform-origin-value) {
  -webkit-transform-origin: $transform-origin-value;
  -moz-transform-origin: $transform-origin-value;
  -ms-transform-origin: $transform-origin-value;
  -o-transform-origin: $transform-origin-value;
  transform-origin: $transform-origin-value;
}
@mixin transition($transition-value) {
  -webkit-transition: $transition-value;
  -moz-transition: $transition-value;
  -ms-transition: $transition-value;
  -o-transition: $transition-value;
  transition: $transition-value;
}
@mixin filter($filter-value) {
  -webkit-filter: $filter-value;
  -moz-filter: $filter-value;
  -ms-filter: $filter-value;
  -o-filter: $filter-value;
  filter: $filter-value;
}
@mixin display($display-value) {
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@mixin for-lg-tab-only {
  @media (max-width: 1199px) { @content; }
}
@mixin for-md-tab-only {
  @media (max-width: 991px) { @content; }
}
@mixin for-sm-tab-only {
  @media (max-width: 767px) { @content; }
}
@mixin for-mobile-only {
  @media (max-width: 575px) { @content; }
}